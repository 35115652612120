import { divide, times } from 'number-precision'

import { MetricValue } from '@graphql/server/typescript'

import {
  LBS_CONVERSION_COEFFICIENT,
  METRIC_AREAS,
  METRIC_DURATIONS,
  METRIC_DISTANCES,
  METRIC_VOLUMES,
  METRIC_WEIGHTS,
  METRICS,
} from '@constants/metric'
import {
  BrandedMetricValue,
  Metric,
  MetricArea,
  MetricDistance,
  MetricVolume,
  MetricWeight,
  ServerSideMetricWeight,
} from '@types'

export const defaultAreaMetric = METRIC_AREAS[1] // m²
export const defaultDistanceMetric = METRIC_DISTANCES[1] // cm
export const defaultDurationMetric = METRIC_DURATIONS[0] // days
export const defaultVolumeMetric = METRIC_VOLUMES[1] // m³
export const defaultWeightMetric = METRIC_WEIGHTS[2] // kg

export const convertDistance = (
  value: number,
  metric: string | MetricDistance, // TODO: Migrate metric unit to string
  newMetric: string | MetricDistance // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm' && newMetric === 'cm') return times(value, 100)
  if (metric === 'm' && newMetric === 'mm') return times(value, 1000)
  if (metric === 'cm' && newMetric === 'm') return divide(value, 100)
  if (metric === 'cm' && newMetric === 'mm') return times(value, 10)
  if (metric === 'mm' && newMetric === 'cm') return divide(value, 10)
  if (metric === 'mm' && newMetric === 'm') return divide(value, 1000)
  return value
}

export const convertArea = (
  value: number,
  metric: string | MetricArea, // TODO: Migrate metric unit to string
  newMetric: string | MetricArea // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm²' && newMetric === 'cm²') return times(value, 10000)
  if (metric === 'cm²' && newMetric === 'm²') return divide(value, 10000)
  return value
}

export const convertVolume = (
  value: number,
  metric: string | MetricVolume, // TODO: Migrate metric unit to string
  newMetric: string | MetricVolume // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm³' && newMetric === 'cm³') return times(value, 1000000)
  if (metric === 'cm³' && newMetric === 'm³') return divide(value, 1000000)
  return value
}

export const convertWeight = (
  value: number,
  metric: ServerSideMetricWeight,
  newMetric: ServerSideMetricWeight
): number => {
  if ((metric === 'g' && newMetric === 'kg') || (metric === 'kg' && newMetric === 'ton'))
    return divide(value, 1000)
  if ((metric === 'kg' && newMetric === 'g') || (metric === 'ton' && newMetric === 'kg'))
    return times(value, 1000)
  if (metric === 'g' && newMetric === 'ton') return divide(value, 1000000)
  if (metric === 'ton' && newMetric === 'g') return times(value, 1000000)

  if (metric === 'g' && newMetric === 'lb') return divide(value, LBS_CONVERSION_COEFFICIENT)
  if (metric === 'kg' && newMetric === 'lb')
    return convertWeight(convertWeight(value, 'kg', 'g'), 'g', 'lb')
  if (metric === 'ton' && newMetric === 'lb')
    return convertWeight(convertWeight(value, 'ton', 'g'), 'g', 'lb')

  if (metric === 'lb' && newMetric === 'g') return times(value, LBS_CONVERSION_COEFFICIENT)
  if (metric === 'lb' && newMetric === 'kg')
    return times(convertWeight(value, 'g', 'kg'), LBS_CONVERSION_COEFFICIENT)
  if (metric === 'lb' && newMetric === 'ton')
    return times(convertWeight(value, 'g', 'ton'), LBS_CONVERSION_COEFFICIENT)

  return value
}

export function isMetricWeight(maybeWeight: string): maybeWeight is MetricWeight {
  return METRIC_WEIGHTS.includes(maybeWeight as MetricWeight)
}

export function assertMetricWeight(maybeWeight: string): asserts maybeWeight is MetricWeight {
  if (!isMetricWeight(maybeWeight)) {
    throw new Error(`Invalid metric weight: ${maybeWeight}`)
  }
}

export function parseMetricWeight(maybeWeight: string): MetricWeight | null {
  return isMetricWeight(maybeWeight) ? (maybeWeight as MetricWeight) : null
}

export function isMetric(data: string): data is Metric {
  return METRICS.includes(data as Metric)
}

export function isBrandedMetricValue(data: MetricValue): data is BrandedMetricValue {
  return !!data.metric && isMetric(data.metric)
}
