import { defineMessages } from 'react-intl'

export default defineMessages({
  autoTracking: {
    id: 'modules.Shipments.autoTracking',
    defaultMessage: 'Auto Tracking',
  },
  autoTrackingIncludeContainers: {
    id: 'modules.Shipments.containerSyncing',
    defaultMessage: 'Container Syncing',
  },
  connection: {
    id: 'modules.Connection.connection',
    defaultMessage: 'Connection',
  },
  chooseConnection: {
    id: 'modules.Connection.chooseConnection',
    defaultMessage: 'Please select a connection',
  },
  followers: {
    id: 'modules.Shipments.followers',
    defaultMessage: 'Followers',
  },
  shipment: {
    id: 'modules.Shipments.shipment',
    defaultMessage: 'Shipment',
  },
  shipmentId: {
    id: 'modules.Shipments.no',
    defaultMessage: 'Shipment ID',
  },
  tags: {
    id: 'modules.Shipments.tags',
    defaultMessage: 'Tags',
  },
  tagsWithout: {
    id: 'modules.Shipments.tagsWithout',
    defaultMessage: 'Tags Without',
  },
  tagsAll: {
    id: 'modules.Shipments.tagsAll',
    defaultMessage: 'All',
  },
  tagsAny: {
    id: 'modules.Shipments.tagsAny',
    defaultMessage: 'Any',
  },
  tagsContainsAll: {
    id: 'modules.Shipments.tagsContainsAll',
    defaultMessage: 'Contains All',
  },
  tagsContainsAny: {
    id: 'modules.Shipments.tagsContainsAny',
    defaultMessage: 'Contains Any',
  },
  carrier: {
    id: 'modules.Shipments.carrier',
    defaultMessage: 'Carrier',
  },
  blNo: {
    id: 'modules.Shipments.blNo',
    defaultMessage: 'House B/L No.',
  },
  masterBlNo: {
    id: 'modules.Shipments.masterBlNo',
    defaultMessage: 'Master B/L No.',
  },
  blDate: {
    id: 'modules.Shipments.blDate',
    defaultMessage: 'B/L Date',
  },
  bookingNo: {
    id: 'modules.Shipments.bookingNo',
    defaultMessage: 'Booking No.',
  },
  bookingDate: {
    id: 'modules.Shipments.bookingDate',
    defaultMessage: 'Booking Date',
  },
  booked: {
    id: 'modules.Shipments.booked',
    defaultMessage: 'Booked',
  },
  invoiceNo: {
    id: 'modules.Shipments.invoiceNo',
    defaultMessage: 'Invoice No.',
  },
  contractNo: {
    id: 'modules.Shipments.contractNo',
    defaultMessage: 'Contract No.',
  },
  incoterms: {
    id: 'modules.Shipments.incoterms',
    defaultMessage: 'Incoterms',
  },
  placeOfReceipt: {
    id: 'modules.Shipments.placeOfReceipt',
    defaultMessage: 'PLACE OF RECEIPT',
  },
  placeOfDelivery: {
    id: 'modules.Shipments.placeOfDelivery',
    defaultMessage: 'PLACE OF DELIVERY',
  },
  forwarder: {
    id: 'modules.Shipments.forwarder',
    defaultMessage: 'Forwarder',
  },
  forwarderNameA: {
    id: 'modules.Shipments.forwarderNameA',
    defaultMessage: 'Forwarder Name (A)',
  },
  forwarderCodeA: {
    id: 'modules.Shipments.forwarderCodeA',
    defaultMessage: 'Forwarder Code (A)',
  },
  forwarderNameB: {
    id: 'modules.Shipments.forwarderNameB',
    defaultMessage: 'Forwarder Name (B)',
  },
  forwarderCodeB: {
    id: 'modules.Shipments.forwarderCodeB',
    defaultMessage: 'Forwarder Code (B)',
  },
  forwarderNameC: {
    id: 'modules.Shipments.forwarderNameC',
    defaultMessage: 'Forwarder Name (C)',
  },
  forwarderCodeC: {
    id: 'modules.Shipments.forwarderCodeC',
    defaultMessage: 'Forwarder Code (C)',
  },
  forwarderNameD: {
    id: 'modules.Shipments.forwarderNameD',
    defaultMessage: 'Forwarder Name (D)',
  },
  forwarderCodeD: {
    id: 'modules.Shipments.forwarderCodeD',
    defaultMessage: 'Forwarder Code (D)',
  },
  exporter: {
    id: 'modules.Shipments.exporter',
    defaultMessage: 'Exporter',
  },
  mainExporter: {
    id: 'modules.Shipments.mainExporter',
    defaultMessage: 'Main Exporter',
  },
  importer: {
    id: 'modules.Shipments.importer',
    defaultMessage: 'Importer',
  },
  PO: {
    id: 'modules.Shipments.PO',
    defaultMessage: 'PO No.',
  },
  numOfVoyages: {
    id: 'modules.Shipments.numOfVoyages',
    defaultMessage: 'Number Of Voyages',
  },
  voyageCode: {
    id: 'modules.Voyages.voyageCode',
    defaultMessage: 'Voyage Code',
  },
  vesselName: {
    id: 'modules.Voyages.vesselName',
    defaultMessage: 'Vessel Name',
  },
  warehouse: {
    id: 'modules.Shipments.warehouse',
    defaultMessage: 'Warehouse',
  },
  cargoReadyWarehouse: {
    id: 'modules.Shipments.cargoReadyWarehouse',
    defaultMessage: 'Cargo Ready Warehouse',
  },
  loadPort: {
    id: 'modules.Shipments.loadPort',
    defaultMessage: 'Load Port',
  },
  transitPort: {
    id: 'modules.Shipments.transitPort',
    defaultMessage: 'Transit Port',
  },
  firstTransitPort: {
    id: 'modules.Shipments.firstTransitPort',
    defaultMessage: 'First Transit Port',
  },
  secondTransitPort: {
    id: 'modules.Shipments.secondTransitPort',
    defaultMessage: 'Second Transit Port',
  },
  secondTransitArrivalApproved: {
    id: 'modules.Shipments.secondTransitArrivalApproved',
    defaultMessage: 'Second Transit Arrival Approved',
  },
  secondTransitDepartureApproved: {
    id: 'modules.Shipments.secondTransitDepartureApproved',
    defaultMessage: 'Second Transit Departure Approved',
  },
  dischargePort: {
    id: 'modules.Shipments.dischargePort',
    defaultMessage: 'Discharge Port',
  },
  dischargePortArrivalApproved: {
    id: 'modules.Shipments.dischargePortArrivalApproved',
    defaultMessage: 'Discharge Port Arrival Approved',
  },
  firstVoyageNo: {
    id: 'modules.Shipments.firstVoyageNo',
    defaultMessage: 'First Voyage No.',
  },
  secondVoyageNo: {
    id: 'modules.Shipments.secondVoyageNo',
    defaultMessage: 'Second Voyage No.',
  },
  thirdVoyageNo: {
    id: 'modules.Shipments.thirdVoyageNo',
    defaultMessage: 'Third Voyage No.',
  },
  firstVesselName: {
    id: 'modules.Shipments.firstVesselName',
    defaultMessage: 'First Vessel Name',
  },
  secondVesselName: {
    id: 'modules.Shipments.secondVesselName',
    defaultMessage: 'Second Vessel Name',
  },
  thirdVesselName: {
    id: 'modules.Shipments.thirdVesselName',
    defaultMessage: 'Third Vessel Name',
  },
  firstVesselCode: {
    id: 'modules.Shipments.firstVesselCode',
    defaultMessage: 'First Vessel Code',
  },
  secondVesselCode: {
    id: 'modules.Shipments.secondVesselCode',
    defaultMessage: 'Second Vessel Code',
  },
  thirdVesselCode: {
    id: 'modules.Shipments.thirdVesselCode',
    defaultMessage: 'Third Vessel Code',
  },
  loadType: {
    id: 'modules.Shipments.loadType',
    defaultMessage: 'Load Type',
  },
  containerType: {
    id: 'modules.Shipments.containerType',
    defaultMessage: 'Container Type',
  },
  containerSize: {
    id: 'modules.Shipments.containerSize',
    defaultMessage: 'Container Size',
  },
  totalVolume: {
    id: 'modules.Shipments.totalVolume',
    defaultMessage: 'Total Volume',
  },
  totalWeight: {
    id: 'modules.Shipments.totalWeight',
    defaultMessage: 'Total Weight',
  },
  totalPackageQuantity: {
    id: 'modules.Shipments.totalPackageQuantity',
    defaultMessage: 'Total Packages',
  },
  containerNo: {
    id: 'modules.Shipments.containerNo',
    defaultMessage: 'Container No.',
  },
  HSCode: {
    id: 'modules.Shipments.HSCode',
    defaultMessage: 'HS Code',
  },
  product: {
    id: 'modules.Shipments.product',
    defaultMessage: 'Product',
  },
  serial: {
    id: 'modules.Shipments.serial',
    defaultMessage: 'Serial',
  },
  productPrice: {
    id: 'modules.Shipments.productPrice',
    defaultMessage: 'Price Per Unit',
  },
  productWeight: {
    id: 'modules.Shipments.productWeight',
    defaultMessage: 'Weight Per Unit',
  },
  productVolume: {
    id: 'modules.Shipments.productVolume',
    defaultMessage: 'Volume Per Unit',
  },
  inspectionApplication: {
    id: 'modules.Shipments.inspectionApplication',
    defaultMessage: 'Inspection Application',
  },
  warehouseArrivalReport: {
    id: 'modules.Shipments.warehouseArrivalReport',
    defaultMessage: 'Warehouse Arrival Report',
  },
  warehouseInspectionReport: {
    id: 'modules.Shipments.warehouseInspectionReport',
    defaultMessage: 'Warehouse Inspection Report',
  },
  warehouseLoadingReport: {
    id: 'modules.Shipments.warehouseLoadingReport',
    defaultMessage: 'Warehouse Loading Report',
  },
  document: {
    id: 'modules.Shipments.document',
    defaultMessage: 'Document',
  },
  inspectionFee: {
    id: 'modules.Shipments.inspectionFee',
    defaultMessage: 'Inspection Fee',
  },
  totalPrice: {
    id: 'modules.Shipments.totalPrice',
    defaultMessage: 'Total Price',
  },
  supplier: {
    id: 'modules.Shipments.supplier',
    defaultMessage: 'Supplier',
  },
  expiry: {
    id: 'modules.Shipments.expiry',
    defaultMessage: 'Expiry',
  },
  availableQuantity: {
    id: 'modules.Shipments.availableQuantity',
    defaultMessage: 'Available Quantity',
  },
  quantity: {
    id: 'modules.Shipments.quantity',
    defaultMessage: 'Quantity',
  },
  volumePP: {
    id: 'modules.Shipments.volumePP',
    defaultMessage: 'CBM/PKG',
  },
  totalCBM: {
    id: 'modules.Shipments.totalCBM',
    defaultMessage: 'Total CBM',
  },
  grossWeightPP: {
    id: 'modules.Shipments.grossWeightPP',
    defaultMessage: 'GWT/PKG',
  },
  totalGWT: {
    id: 'modules.Shipments.totalGWT',
    defaultMessage: 'Total Gwt',
  },
  packageQuantity: {
    id: 'modules.Shipments.packageQuantity',
    defaultMessage: 'Package Quantity',
  },
  packageVolume: {
    id: 'modules.Shipments.packageVolume',
    defaultMessage: 'Package Gross Volume',
  },
  pricePU: {
    id: 'modules.Shipments.pricePU',
    defaultMessage: 'Price/Unit',
  },
  productCost: {
    id: 'modules.Shipments.productCost',
    defaultMessage: 'Product Cost',
  },
  productFreightCost: {
    id: 'modules.Shipments.productFreightCost',
    defaultMessage: 'Product Freight Cost',
  },
  taxCost: {
    id: 'modules.Shipments.taxCost',
    defaultMessage: 'Tax Cost',
  },
  costPU: {
    id: 'modules.Shipments.costPU',
    defaultMessage: 'Cost/Unit',
  },
  totalCost: {
    id: 'modules.Shipments.totalCost',
    defaultMessage: 'Total Cost',
  },
  freightCost: {
    id: 'modules.Shipments.freightCost',
    defaultMessage: 'Freight Cost',
  },
  inlandExporterCost: {
    id: 'modules.Shipments.inlandExporterCost',
    defaultMessage: 'Inland Exporter Cost',
  },
  inlandImporterCost: {
    id: 'modules.Shipments.inlandImporterCost',
    defaultMessage: 'Inland Importer Cost',
  },
  documentFee: {
    id: 'modules.Shipments.documentFee',
    defaultMessage: 'Document Fee',
  },
  deliveryOrderFee: {
    id: 'modules.Shipments.deliveryOrderFee',
    defaultMessage: 'D/O Fee',
  },
  exporterMiscFee: {
    id: 'modules.Shipments.exporterMiscFee',
    defaultMessage: 'Exporter Misc Fee',
  },
  importerMiscFee: {
    id: 'modules.Shipments.importerMiscFee',
    defaultMessage: 'Importer Misc Fee',
  },
  tariffTax: {
    id: 'modules.Shipments.tariffTax',
    defaultMessage: 'Tariff Tax',
  },
  consumptionTax: {
    id: 'modules.Shipments.consumptionTax',
    defaultMessage: 'Consumption Tax',
  },
  exporterCurrency: {
    id: 'modules.Shipments.exporterCurrency',
    defaultMessage: 'Exporter Currency',
  },
  importerCurrency: {
    id: 'modules.Shipments.importerCurrency',
    defaultMessage: 'Importer Currency',
  },
  firstExchangeRate: {
    id: 'modules.Shipments.firstExchangeRate',
    defaultMessage: 'Exchange Rate 1',
  },
  secondExchangeRate: {
    id: 'modules.Shipments.secondExchangeRate',
    defaultMessage: 'Exchange Rate 2',
  },
  ratio: {
    id: 'modules.Shipments.ratio',
    defaultMessage: 'First Payment Ratio',
  },
  memo: {
    id: 'modules.Shipments.memo',
    defaultMessage: 'Memo',
  },
  cargoReady: {
    id: 'modules.Shipments.cargoReady',
    defaultMessage: 'Cargo Ready Initial Date',
  },
  cargoReadyCurrentDate: {
    id: 'modules.Shipments.cargoReadyCurrentDate',
    defaultMessage: 'Cargo Ready Current Date',
  },
  cargoReadyDifferenceDate: {
    id: 'modules.Shipments.cargoReadyDifferenceDate',
    defaultMessage: 'Cargo Ready Date Difference',
  },
  cargoReadyResultDate: {
    id: 'modules.Shipments.cargoReadyResultDate',
    defaultMessage: 'CARGO READY RESULT DATE',
  },
  cargoReadyRevisions: {
    id: 'modules.Shipments.cargoReadyRevisions',
    defaultMessage: 'Cargo Ready Revisions',
  },
  cargoReadyApproved: {
    id: 'modules.Shipments.cargoReadyApproved',
    defaultMessage: 'Cargo Ready Approved',
  },
  departure: {
    id: 'modules.Shipments.departure',
    defaultMessage: 'Departure',
  },
  estimatedDeparture: {
    id: 'modules.Shipments.estimatedDeparture',
    defaultMessage: 'ETD',
  },
  arrival: {
    id: 'modules.Shipments.arrival',
    defaultMessage: 'Arrival',
  },
  estimatedArrival: {
    id: 'modules.Shipments.estimatedArrival',
    defaultMessage: 'ETA',
  },
  customClearance: {
    id: 'modules.Shipments.customClearance',
    defaultMessage: 'Customs Clearance Initial Date',
  },
  customClearanceCurrentDate: {
    id: 'modules.Shipments.customClearanceCurrentDate',
    defaultMessage: 'Customs Clearance Current Date',
  },
  customClearanceDifferenceDate: {
    id: 'modules.Shipments.customClearanceDifferenceDate',
    defaultMessage: 'Customs Clearance Date Difference',
  },
  customClearanceResultDate: {
    id: 'modules.Shipments.customClearanceResultDate',
    defaultMessage: 'CUSTOMS CLEARANCE RESULT DATE',
  },
  customClearanceRevisions: {
    id: 'modules.Shipments.customClearanceRevisions',
    defaultMessage: 'Customs Clearance Revisions',
  },
  customClearanceApproved: {
    id: 'modules.Shipments.customClearanceApproved',
    defaultMessage: 'Customs Clearance Approved',
  },
  warehouseArrival: {
    id: 'modules.Shipments.warehouseArrival',
    defaultMessage: 'Warehouse Arrival',
  },
  warehouseArrivalCurrentDate: {
    id: 'modules.Shipments.warehouseArrivalCurrentDate',
    defaultMessage: 'Warehouse Arrival Current Date',
  },
  warehouseArrivalDifferenceDate: {
    id: 'modules.Shipments.warehouseArrivalDifferenceDate',
    defaultMessage: 'Warehouse Arrival Different Date',
  },
  warehouseArrivalResultDate: {
    id: 'modules.Shipments.warehouseArrivalResultDate',
    defaultMessage: 'WAREHOUSE ARRIVAL RESULT DATE',
  },
  warehouseArrivalRevisions: {
    id: 'modules.Shipments.warehouseArrivalRevisions',
    defaultMessage: 'Warehouse Arrival Revisions',
  },
  warehouseArrivalApproved: {
    id: 'modules.Shipments.warehouseArrivalApproved',
    defaultMessage: 'Warehouse Arrival Approved',
  },
  deliveryReady: {
    id: 'modules.Shipments.deliveryReady',
    defaultMessage: 'Delivery Ready Initial Date',
  },
  deliveryReadyCurrentDate: {
    id: 'modules.Shipments.deliveryReadyCurrentDate',
    defaultMessage: 'Delivery Ready Current Date',
  },
  deliveryReadyDifferenceDate: {
    id: 'modules.Shipments.deliveryReadyDifferenceDate',
    defaultMessage: 'Delivery Ready Date Difference',
  },
  deliveryReadyResultDate: {
    id: 'modules.Shipments.deliveryReadyResultDate',
    defaultMessage: 'DELIVERY READY RESULT DATE',
  },
  deliveryReadyRevisions: {
    id: 'modules.Shipments.deliveryReadyRevisions',
    defaultMessage: 'Delivery Ready Revisions',
  },
  deliveryReadyApproved: {
    id: 'modules.Shipments.deliveryReadyApproved',
    defaultMessage: 'Delivery Ready Arrival Approved',
  },
  status: {
    id: 'modules.Shipments.status',
    defaultMessage: 'Status',
  },
  createdAt: {
    id: 'modules.Shipments.createdAt',
    defaultMessage: 'Date Created',
  },
  updatedAt: {
    id: 'modules.Shipments.updatedAt',
    defaultMessage: 'Last Modified',
  },
  user: {
    id: 'modules.Shipments.user',
    defaultMessage: 'Staff',
  },
  newShipment: {
    id: 'modules.Shipments.newShipment',
    defaultMessage: 'New',
  },
  newBatch: {
    id: 'modules.Shipments.newBatch',
    defaultMessage: 'New Batch',
  },
  insurance: {
    id: 'modules.Shipments.insurance',
    defaultMessage: 'Insurance',
  },
  active: {
    id: 'modules.Shipments.active',
    defaultMessage: 'Active',
  },
  archived: {
    id: 'modules.Shipments.archived',
    defaultMessage: 'Archived',
  },
  unseenNotification: {
    id: 'modules.Shipments.unseenNotification',
    defaultMessage: 'UPDATES',
  },
  completed: {
    id: 'modules.Shipments.completed',
    defaultMessage: 'Completed',
  },
  approved: {
    id: 'modules.Shipments.approved',
    defaultMessage: 'Approved',
  },
  statusCargoReadyInitialDate: {
    id: 'modules.Shipments.status.cargoReadyInitialDate',
    defaultMessage: 'Cargo Ready Initial Date',
  },
  statusCargoReadyResultDate: {
    id: 'modules.Shipments.status.cargoReadyResultDate',
    defaultMessage: 'Cargo Ready Result Date',
  },
  statusCargoReadyCurrentDate: {
    id: 'modules.Shipments.status.cargoReadyCurrentDate',
    defaultMessage: 'Cargo Ready Current Date',
  },
  statusCustomClearanceInitialDate: {
    id: 'modules.Shipments.status.customClearanceInitialDate',
    defaultMessage: 'Custom Clearance Initial Date',
  },
  statusCustomClearanceResultDate: {
    id: 'modules.Shipments.status.customClearanceResultDate',
    defaultMessage: 'Custom Clearance Result Date',
  },
  statusCustomClearanceCurrentDate: {
    id: 'modules.Shipments.status.customClearanceCurrentDate',
    defaultMessage: 'Custom Clearance Current Date',
  },
  statusWarehouseArrivalInitialDate: {
    id: 'modules.Shipments.status.warehouseArrivalInitialDate',
    defaultMessage: 'Warehouse Arrival Initial Date',
  },
  statusWarehouseArrivalResultDate: {
    id: 'modules.Shipments.status.warehouseArrivalResultDate',
    defaultMessage: 'Warehouse Arrival Result Date',
  },
  statusWarehouseArrivalCurrentDate: {
    id: 'modules.Shipments.status.warehouseArrivalCurrentDate',
    defaultMessage: 'Warehouse Arrival Current Date',
  },
  statusLoadPortDepartureInitialDate: {
    id: 'modules.Shipments.status.loadPortDepartureInitialDate',
    defaultMessage: 'Load Port Departure Initial Date',
  },
  statusLoadPortDepartureResultDate: {
    id: 'modules.Shipments.status.loadPortDepartureResultDate',
    defaultMessage: 'Load Port Departure Result Date',
  },
  statusLoadPortDepartureCurrentDate: {
    id: 'modules.Shipments.status.loadPortDepartureCurrentDate',
    defaultMessage: 'Load Port Departure Current Date',
  },
  statusFirstTransitArrivalInitialDate: {
    id: 'modules.Shipments.status.firstTransitArrivalInitialDate',
    defaultMessage: 'First Transit Arrival Initial Date',
  },
  statusFirstTransitArrivalResultDate: {
    id: 'modules.Shipments.status.firstTransitArrivalResultDate',
    defaultMessage: 'First Transit Arrival Result Date',
  },
  statusFirstTransitArrivalCurrentDate: {
    id: 'modules.Shipments.status.firstTransitArrivalCurrentDate',
    defaultMessage: 'First Transit Arrival Current Date',
  },
  statusFirstTransitDepartureInitialDate: {
    id: 'modules.Shipments.status.firstTransitDepartureInitialDate',
    defaultMessage: 'First Transit Departure Initial Date',
  },
  statusFirstTransitDepartureResultDate: {
    id: 'modules.Shipments.status.firstTransitDepartureResultDate',
    defaultMessage: 'First Transit Departure Result Date',
  },
  statusFirstTransitDepartureCurrentDate: {
    id: 'modules.Shipments.status.firstTransitDepartureCurrentDate',
    defaultMessage: 'First Transit Departure Current Date',
  },
  statusSecondTransitArrivalInitialDate: {
    id: 'modules.Shipments.status.secondTransitArrivalInitialDate',
    defaultMessage: 'Second Transit Arrival Initial Date',
  },
  statusSecondTransitArrivalResultDate: {
    id: 'modules.Shipments.status.secondTransitArrivalResultDate',
    defaultMessage: 'Second Transit Arrival Result Date',
  },
  statusSecondTransitArrivalCurrentDate: {
    id: 'modules.Shipments.status.secondTransitArrivalCurrentDate',
    defaultMessage: 'Second Transit Arrival Current Date',
  },
  statusSecondTransitDepartureInitialDate: {
    id: 'modules.Shipments.status.secondTransitDepartureInitialDate',
    defaultMessage: 'Second Transit Departure',
  },
  statusSecondTransitDepartureResultDate: {
    id: 'modules.Shipments.status.secondTransitDepartureResultDate',
    defaultMessage: 'Second Transit Departure Result Date',
  },
  statusSecondTransitDepartureCurrentDate: {
    id: 'modules.Shipments.status.secondTransitDepartureCurrentDate',
    defaultMessage: 'Second Transit Departure Current Date',
  },
  statusDischargePortArrivalInitialDate: {
    id: 'modules.Shipments.status.dischargePortArrivalInitialDate',
    defaultMessage: 'Discharge Port Arrival Initial Date',
  },
  statusDischargePortArrivalResultDate: {
    id: 'modules.Shipments.status.dischargePortArrivalResultDate',
    defaultMessage: 'Discharge Port Arrival Result Date',
  },
  statusDischargePortArrivalCurrentDate: {
    id: 'modules.Shipments.status.dischargePortArrivalCurrentDate',
    defaultMessage: 'Discharge Port Arrival Current Date',
  },
  statusDeliveryReadyInitialDate: {
    id: 'modules.Shipments.status.deliveryReadyInitialDate',
    defaultMessage: 'Delivery Ready Initial Date',
  },
  statusDeliveryReadyResultDate: {
    id: 'modules.Shipments.status.deliveryReadyResultDate',
    defaultMessage: 'Delivery Ready Result Date',
  },
  statusDeliveryReadyCurrentDate: {
    id: 'modules.Shipments.status.deliveryReadyCurrentDate',
    defaultMessage: 'Delivery Ready Current Date',
  },
  tooltipBLNo: {
    id: 'modules.Shipments.tooltip.BLNo',
    defaultMessage: '[BL No.] {BL}',
  },
  tooltipShipmentID: {
    id: 'modules.Shipments.tooltip.shipmentID',
    defaultMessage: '[Shipment ID] {no}',
  },
  tooltipExporter: {
    id: 'modules.Shipments.tooltip.exporter',
    defaultMessage: '[Exporter] {exporter}',
  },
  tooltipProducts: {
    id: 'modules.Shipments.tooltip.products',
    defaultMessage: '[Products] {products}',
  },
  tooltipDetails: {
    id: 'modules.Shipments.tooltip.details',
    defaultMessage: 'View Details',
  },
  noShipments: {
    id: 'modules.Shipments.noShipments',
    defaultMessage: 'No shipments found.',
  },
  noContainers: {
    id: 'modules.Shipments.noContainers',
    defaultMessage: 'No containers found.',
  },
  noBL: {
    id: 'modules.Shipments.noBL',
    defaultMessage: 'No B/L',
  },
  noShipmentID: {
    id: 'modules.Shipments.noShipmentID',
    defaultMessage: 'No Shipment ID',
  },
  noItems: {
    id: 'modules.Shipments.noItems',
    defaultMessage: 'No items found.',
  },
  next: {
    id: 'modules.Shipments.next',
    defaultMessage: 'Next',
  },
  required: {
    id: 'modules.Shipments.validation.required',
    defaultMessage: 'Required',
  },
  idNotAvailable: {
    id: 'modules.Shipments.validation.idNotAvailable',
    defaultMessage: 'Shipment ID is not available',
  },
  chooseExporter: {
    id: 'modules.Shipments.validation.chooseExporter',
    defaultMessage: 'Please choose an Exporter first',
  },
  choosePort: {
    id: 'components.NavBar.Filter.portPlaceholder',
    defaultMessage: 'Please choose a port',
  },
  bl: {
    id: 'modules.Shipments.bl',
    defaultMessage: 'B/L',
  },
  invoice: {
    id: 'modules.Shipments.invoice',
    defaultMessage: 'Invoice',
  },
  packingList: {
    id: 'modules.Shipments.packingList',
    defaultMessage: 'Packing List',
  },
  importDeclaration: {
    id: 'modules.Shipments.importDeclaration',
    defaultMessage: 'Import declaration',
  },
  exportDeclaration: {
    id: 'modules.Shipments.exportDeclaration',
    defaultMessage: 'Export Declaration',
  },
  shippingAdvice: {
    id: 'modules.Shipments.shippingAdvice',
    defaultMessage: 'Shipping Advice',
  },
  image: {
    id: 'modules.Shipments.image',
    defaultMessage: 'Image',
  },
  other: {
    id: 'modules.Shipments.other',
    defaultMessage: 'Other',
  },
  sectionDocuments: {
    id: 'modules.Shipments.sectionDocuments',
    defaultMessage: 'Documents',
  },
  sectionGeneral: {
    id: 'modules.Shipments.sectionGeneral',
    defaultMessage: 'General',
  },
  sectionShipmentInfo: {
    id: 'modules.Shipments.sectionShipmentInfo',
    defaultMessage: 'Shipment Info',
  },
  sectionItems: {
    id: 'modules.Shipments.sectionItems',
    defaultMessage: 'Items',
  },
  sectionTimeline: {
    id: 'modules.Shipments.sectionTimeline',
    defaultMessage: 'Timeline',
  },
  sectionContainers: {
    id: 'modules.Shipments.sectionContainers',
    defaultMessage: 'Containers',
  },
  sectionCosts: {
    id: 'modules.Shipments.sectionCosts',
    defaultMessage: 'Costs',
  },
  sectionTransportation: {
    id: 'modules.Shipments.sectionTransportation',
    defaultMessage: 'Transportation',
  },
  sectionDocumentation: {
    id: 'modules.Shipments.sectionDocumentation',
    defaultMessage: 'Documentation',
  },
  sectionTax: {
    id: 'modules.Shipments.sectionTax',
    defaultMessage: 'Tax',
  },
  sectionPayment: {
    id: 'modules.Shipments.sectionPayment',
    defaultMessage: 'Payment',
  },
  sectionPackingList: {
    id: 'modules.Shipments.sectionPackingList',
    defaultMessage: 'Packing List',
  },
  sectionCostResults: {
    id: 'modules.Shipments.sectionCostResults',
    defaultMessage: 'Cost Results',
  },
  addBatchItem: {
    id: 'modules.Shipments.addBatchItem',
    defaultMessage: 'Add Batch',
  },
  createBatchItem: {
    id: 'modules.Shipments.createBatchItem',
    defaultMessage: 'New Batch',
  },
  addMultiBatchItems: {
    id: 'modules.Shipments.addMultiBatchItems',
    defaultMessage: 'Add Multi Batches',
  },
  moveSelected: {
    id: 'modules.Shipments.moveSelected',
    defaultMessage: 'Choose ({count})',
  },
  save: {
    id: 'modules.Shipments.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'modules.Shipments.cancel',
    defaultMessage: 'Cancel',
  },
  picked: {
    id: 'modules.Shipments.picked',
    defaultMessage: 'Selected',
  },
  options: {
    id: 'modules.Shipments.options',
    defaultMessage: 'Available',
  },
  selectAll: {
    id: 'modules.Shipments.selectAll',
    defaultMessage: 'Select All',
  },
  selectBatches: {
    id: 'modules.Shipments.selectBatches',
    defaultMessage: 'Select Batches',
  },
  newContainer: {
    id: 'modules.Shipments.newContainer',
    defaultMessage: 'New Container',
  },
  moveBatches: {
    id: 'modules.Shipments.moveBatches',
    defaultMessage: 'Move Batches',
  },
  clearSelection: {
    id: 'modules.Shipments.clearSelection',
    defaultMessage: 'Clear Selections',
  },
  sectionLogs: {
    id: 'modules.Shipments.sectionLogs',
    defaultMessage: 'Logs & Message Board',
  },
  arrivalPort: {
    id: 'modules.Shipments.dischargePort',
    defaultMessage: 'Discharge Port',
  },
  dischargePortArrival: {
    id: 'modules.Shipments.dischargePortArrival',
    defaultMessage: 'Discharge Port Arrival Initial Date',
  },
  dischargePortArrivalCurrentDate: {
    id: 'modules.Shipments.sort.dischargePortArrivalCurrentDate',
    defaultMessage: 'Discharge Port Arrival Current Date',
  },
  dischargePortArrivalDifferenceDate: {
    id: 'modules.Shipments.sort.dischargePortArrivalDifferenceDate',
    defaultMessage: 'Discharge Port Arrival Date Difference',
  },
  dischargePortArrivalResultDate: {
    id: 'components.Shipments.dischargePortArrivalResultDate',
    defaultMessage: 'DISCHARGE PORT ARRIVAL RESULT DATE',
  },
  dischargePortArrivalRevisions: {
    id: 'modules.Shipments.dischargePortArrivalRevisions',
    defaultMessage: 'Discharge Port Arrival Revisions',
  },
  firstTransitPortArrival: {
    id: 'modules.Shipments.firstTransitPortArrival',
    defaultMessage: 'First Transit Arrival Initial Date',
  },
  firstTransitPortArrivalCurrentDate: {
    id: 'modules.Shipments.firstTransitPortArrivalCurrentDate',
    defaultMessage: 'First Transit Arrival Current Date',
  },
  firstTransitPortArrivalDifferenceDate: {
    id: 'modules.Shipments.firstTransitPortArrivalDifferenceDate',
    defaultMessage: 'First Transit Arrival Date Difference',
  },
  firstTransitPortArrivalResultDate: {
    id: 'modules.Shipments.firstTransitPortArrivalResultDate',
    defaultMessage: 'FIRST TRANSIT ARRIVAL RESULT DATE',
  },
  firstTransitPortArrivalRevisions: {
    id: 'modules.Shipments.firstTransitPortArrivalRevisions',
    defaultMessage: 'First Transit Arrival Revisions',
  },
  firstTransitPortDepartureCurrentDate: {
    id: 'modules.Shipments.firstTransitPortDepartureCurrentDate',
    defaultMessage: 'First Transit Departure Current Date',
  },
  firstTransitPortDepartureDifferenceDate: {
    id: 'modules.Shipments.firstTransitPortDepartureDifferenceDate',
    defaultMessage: 'First Transit Departure Date Difference',
  },
  firstTransitPortDepartureResultDate: {
    id: 'modules.Shipments.firstTransitPortDepartureResultDate',
    defaultMessage: 'FIRST TRANSIT DEPARTURE RESULT DATE',
  },
  firstTransitPortDeparture: {
    id: 'modules.Shipments.firstTransitPortDeparture',
    defaultMessage: 'First Transit Departure Initial Date',
  },
  firstTransitPortDepartureRevisions: {
    id: 'modules.Shipments.firstTransitPortDepartureRevisions',
    defaultMessage: 'First Transit Departure Revisions',
  },
  secondTransitPortArrival: {
    id: 'modules.Shipments.secondTransitPortArrival',
    defaultMessage: 'Second Transit Arrival Initial Date',
  },
  secondTransitPortArrivalCurrentDate: {
    id: 'modules.Shipments.secondTransitPortArrivalCurrentDate',
    defaultMessage: 'Second Transit Arrival Current Date',
  },
  secondTransitPortArrivalDifferenceDate: {
    id: 'modules.Shipments.secondTransitPortArrivalDifferenceDate',
    defaultMessage: 'Second Transit Arrival Date Difference',
  },
  secondTransitPortArrivalResultDate: {
    id: 'modules.Shipments.secondTransitPortArrivalResultDate',
    defaultMessage: 'SECOND TRANSIT ARRIVAL RESULT DATE',
  },
  secondTransitPortArrivalRevisions: {
    id: 'modules.Shipments.secondTransitPortArrivalRevisions',
    defaultMessage: 'Second Transit Arrival Revisions',
  },
  secondTransitPortDeparture: {
    id: 'modules.Shipments.secondTransitPortDeparture',
    defaultMessage: 'Second Transit Departure Initial Date',
  },
  secondTransitPortDepartureCurrentDate: {
    id: 'modules.Shipments.secondTransitPortDepartureCurrentDate',
    defaultMessage: 'Second Transit Departure Current Date',
  },
  secondTransitPortDepartureDifferenceDate: {
    id: 'modules.Shipments.secondTransitPortDepartureDifferenceDate',
    defaultMessage: 'Second Transit Departure Date Difference',
  },
  secondTransitPortDepartureResultDate: {
    id: 'modules.Shipments.secondTransitPortDepartureResultDate',
    defaultMessage: 'SECOND TRANSIT DEPARTURE RESULT DATE',
  },
  secondTransitPortDepartureRevisions: {
    id: 'modules.Shipments.secondTransitPortDepartureRevisions',
    defaultMessage: 'Second Transit Departure Revisions',
  },
  loadPortDeparture: {
    id: 'modules.Shipments.loadPortDeparture',
    defaultMessage: 'Load Port Departure Initial Date',
  },
  loadPortDepartureCurrentDate: {
    id: 'modules.Shipments.loadPortDepartureCurrentDate',
    defaultMessage: 'Load Port Departure Current Date',
  },
  loadPortDepartureDifferenceDate: {
    id: 'modules.Shipments.loadPortDepartureDifferenceDate',
    defaultMessage: 'Load Port Departure Date Difference',
  },
  loadPortDepartureResultDate: {
    id: 'modules.Shipments.loadPortDepartureResultDate',
    defaultMessage: 'LOAD PORT DEPARTURE RESULT DATE',
  },
  loadPortDepartureRevisions: {
    id: 'modules.Shipments.loadPortDepartureRevisions',
    defaultMessage: 'Load Port Departure Revisions',
  },
  loadPortDepartureApproved: {
    id: 'modules.Shipments.loadPortDepartureApproved',
    defaultMessage: 'Load Port Approved',
  },
  firstTransitArrivalApproved: {
    id: 'modules.Shipments.firstTransitArrivalApproved',
    defaultMessage: 'First Transit Arrival Approved',
  },
  firstTransitDepartureApproved: {
    id: 'modules.Shipments.firstTransitDepartureApproved',
    defaultMessage: 'First Transit Departure Approved',
  },
  agreedDateLabel: {
    id: 'modules.Shipments.agreedDateLabel',
    defaultMessage: 'Agreed',
  },
  actualDateLabel: {
    id: 'modules.Shipments.actualDateLabel',
    defaultMessage: 'Actual',
  },
  totalProducts: {
    id: 'modules.Shipments.totalProducts',
    defaultMessage: 'Total Products',
  },
  totalBatches: {
    id: 'modules.Shipments.totalBatches',
    defaultMessage: 'Total Batches',
  },
  totalOrders: {
    id: 'modules.Shipments.totalOrders',
    defaultMessage: 'Total Orders',
  },
  totalContainers: {
    id: 'modules.Shipments.totalContainers',
    defaultMessage: 'Total Containers',
  },
  totalBatchQuantity: {
    id: 'modules.Shipments.totalBatchQuantity',
    defaultMessage: 'Total Batch Quantity',
  },
  logs: {
    id: 'modules.Shipments.logs',
    defaultMessage: 'Logs',
  },
  messages: {
    id: 'modules.Shipments.messages',
    defaultMessage: 'Messages',
  },
  mask: {
    id: 'modules.Shipments.mask',
    defaultMessage: 'Custom fields template',
  },
  relatedExporters: {
    id: 'modules.Shipments.relatedExporters',
    defaultMessage: 'Related Exporters',
  },
  mainExporterChangeMessage: {
    id: 'modules.Shipment.mainExporterChangeMessage',
    defaultMessage:
      'Changing the Main Exporter will remove all Batches of the current Main Exporter. Are you sure you want to change the Main Exporter?',
  },
  mainExporterSelectMessage: {
    id: 'modules.Shipment.mainExporterSelectMessage',
    defaultMessage:
      'Selecting a Main Exporter will allow them access to this Shipment. However, it will mean only Batches of the Main Exporter can be used in this Shipment. All Batches that are currently in this Shipment that do not belong to this Main Exporter will be removed. Are you sure you want to select a Main Exporter?',
  },
  mainExporterDeselectMessage: {
    id: 'modules.Shipment.mainExporterDeselectMessage',
    defaultMessage: 'Are you sure you want to change the Main Exporter?',
  },
  sharedPartners: {
    id: 'modules.Shipments.sharedPartners',
    defaultMessage: 'Shared Partners',
  },
  exportCustomsClearance: {
    id: 'modules.Shipments.exportCustomsClearance',
    defaultMessage: 'Export Customs Clearance',
  },
  cyCfsArrivalDate: {
    id: 'modules.Shipments.cyCfsArrivalDate',
    defaultMessage: 'CY/CFS Arrival Date',
  },
  cyCfsCut: {
    id: 'modules.Shipments.cyCfsCut',
    defaultMessage: 'CY/CFS Cut',
  },
  cyCfsOpen: {
    id: 'modules.Shipments.cyCfsOpen',
    defaultMessage: 'CY/CFS Open',
  },
})

export const shipmentSortMessages = defineMessages({
  updatedAt: {
    id: 'modules.Shipments.sort.updatedAt',
    defaultMessage: 'Last Modified',
  },
  createdAt: {
    id: 'modules.Shipments.sort.createdAt',
    defaultMessage: 'Date Created',
  },
  shipmentId: {
    id: 'modules.Shipments.sort.no',
    defaultMessage: 'Shipment ID',
  },
  blNo: {
    id: 'modules.Shipments.sort.blNo',
    defaultMessage: 'B/L No.',
  },
  vesselName: {
    id: 'modules.Shipments.sort.vesselName',
    defaultMessage: 'Vessel Name',
  },
  cargoReady: {
    id: 'modules.Shipments.sort.cargoReady',
    defaultMessage: 'Cargo Ready',
  },
  loadPort: {
    id: 'modules.Shipments.sort.loadPort',
    defaultMessage: 'Load Port',
  },
  loadPortDeparture: {
    id: 'modules.Shipments.sort.loadPortDeparture',
    defaultMessage: 'Load Port Departure',
  },
  firstTransitPortArrival: {
    id: 'modules.Shipments.sort.firstTransitPortArrival',
    defaultMessage: '1st Transit Arrival',
  },
  firstTransitPortDeparture: {
    id: 'modules.Shipments.sort.firstTransitPortDeparture',
    defaultMessage: '1st Transit Departure',
  },
  secondTransitPortArrival: {
    id: 'modules.Shipments.sort.secondTransitPortArrival',
    defaultMessage: '2nd Transit Arrival',
  },
  secondTransitPortDeparture: {
    id: 'modules.Shipments.sort.secondTransitPortDeparture',
    defaultMessage: '2nd Transit Departure',
  },
  dischargePort: {
    id: 'modules.Shipments.sort.dischargePort',
    defaultMessage: 'Discharge Port',
  },
  dischargePortArrival: {
    id: 'modules.Shipments.sort.dischargePortArrival',
    defaultMessage: 'Discharge Port Arrival',
  },
  customClearance: {
    id: 'modules.Shipments.sort.customClearance',
    defaultMessage: 'Customs Clearance',
  },
  warehouseArrival: {
    id: 'modules.Shipments.sort.warehouseArrival',
    defaultMessage: 'Warehouse Arrival',
  },
  deliveryReady: {
    id: 'modules.Shipments.sort.deliveryReady',
    defaultMessage: 'Delivery Ready',
  },
})
