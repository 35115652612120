import { pick } from 'lodash'
// ! React MUST BE IN SCOPE to import this file into CRA
import React from 'react'
// ! React MUST BE IN SCOPE to import this file into CRA

import {
  AutoTrackingTransportType,
  Maybe,
  Organization,
  Port,
  PortInput,
  TransportCodeType,
  TransportType,
  UsersOrganizationFragment,
  Voyage,
} from '@graphql/server/typescript'

import Icon from '@components/Icon'
import { Payload } from '@types'
import { isAvailable } from '@utils/data'

import { TRANSPORT_TYPE_PROTO_ARR } from './constants'
import { VoyageIcon, PortOption, TransportCodeIcon } from './types'

export const getCarrierType = ({
  carrierInput,
}: {
  carrierInput: Pick<PortInput, 'seaport' | 'airport'>
}): keyof Pick<PortInput, 'seaport' | 'airport'> =>
  // @ts-ignore must be at least one key defined
  Object.keys(carrierInput).find((key) => carrierInput[key])

export const autoTrackingTransportTypeFromPortType = ({
  carrierType: portType,
}: {
  carrierType: keyof Pick<PortInput, 'seaport' | 'airport'>
}): AutoTrackingTransportType => {
  switch (portType) {
    case 'seaport':
      return AutoTrackingTransportType.Sea
    case 'airport':
      return AutoTrackingTransportType.Air
    default:
      return AutoTrackingTransportType.Sea
  }
}

export const portTypeFromAutoTrackingTransportType = ({
  autoTrackingTransportType,
}: {
  autoTrackingTransportType: AutoTrackingTransportType
}): keyof Pick<PortInput, 'seaport' | 'airport'> => {
  switch (autoTrackingTransportType) {
    case AutoTrackingTransportType.Sea:
      return 'seaport'
    case AutoTrackingTransportType.Air:
      return 'airport'
    default:
      return 'seaport'
  }
}

export const getTransportationCodeTypeIcon = ({
  transportCodeType,
}: {
  transportCodeType?: Maybe<TransportCodeType>
}): TransportCodeIcon | null => {
  switch (transportCodeType) {
    case 'IATA':
      return 'IATA'
    default:
      return null
  }
}

export const getTransportationTypeIcon = ({
  transportType,
}: {
  transportType: TransportType
}): VoyageIcon => {
  switch (transportType) {
    case 'Sea':
      return 'SHIPMENT'
    case 'Air':
      return 'PLANE'
    case 'Road':
      return 'ROAD'
    case 'Rail':
      return 'RAIL'
    case 'Dry':
      return 'DRY'
    default:
      return 'UNKNOWN'
  }
}

export const getPortIcon = ({ portType }: { portType: keyof PortInput }): VoyageIcon =>
  getTransportationTypeIcon({ transportType: transportTypeFromPortType({ portType }) })

export const getVoyageIcon = ({
  voyage,
}: {
  voyage: Voyage | { departurePort: Port | PortInput; arrivalPort: Port | PortInput }
}): VoyageIcon => {
  if (!voyage.departurePort) return 'UNKNOWN'
  if (!voyage.arrivalPort) return 'UNKNOWN'
  const departurePortType = portTypeFromPort({ port: voyage.departurePort })
  const arrivalPortType = portTypeFromPort({ port: voyage.arrivalPort })

  const leftIcon: VoyageIcon = getPortIcon({ portType: departurePortType })
  const rightIcon: VoyageIcon = getPortIcon({ portType: arrivalPortType })

  if (leftIcon === 'UNKNOWN' || rightIcon === 'UNKNOWN') return 'UNKNOWN'
  if (leftIcon === rightIcon && (leftIcon === 'SHIPMENT' || leftIcon === 'PLANE')) return leftIcon
  return 'LAND'
}

/**
 * The server may return a `TransportType` as an integer. Use this to convert it to a real `TransportType` value.
 */
export const transportTypeFromTransportTypeInteger = (int: number): TransportType =>
  TRANSPORT_TYPE_PROTO_ARR[int] ?? TransportType.Sea

/**
 * The server may return a `TransportType` as an integer. Use this to convert it to a real `PortInput` key value.
 */
export const portTypeFromTransportTypeInteger = (int: number): keyof PortInput =>
  portTypeFromTransportType({ transportType: transportTypeFromTransportTypeInteger(int) })

export const portTypeFromPort = ({ port }: { port: Port | PortInput }): keyof PortInput => {
  if (port.seaport) return 'seaport'
  if (port.airport) return 'airport'
  if (port.road) return 'road'
  if (port.rail) return 'rail'
  if (port.dryport) return 'dryport'
  return 'seaport'
}

export const getSomePortCode = ({ port }: { port: Port | PortInput }): string =>
  port[portTypeFromPort({ port })] || ''

export const transportTypeFromPortType = ({
  portType,
}: {
  portType: keyof PortInput
}): TransportType => {
  switch (portType) {
    case 'seaport':
      return TransportType.Sea
    case 'airport':
      return TransportType.Air
    case 'road':
      return TransportType.Road
    case 'rail':
      return TransportType.Rail
    case 'dryport':
    default:
      return TransportType.Dry
  }
}

export const portTypeFromTransportType = ({
  transportType,
}: {
  transportType: TransportType
}): keyof PortInput => {
  switch (transportType) {
    case 'Sea':
      return 'seaport'
    case 'Air':
      return 'airport'
    case 'Road':
      return 'road'
    case 'Rail':
      return 'rail'
    case 'Dry':
      return 'dryport'
    default:
      return 'seaport'
  }
}

export const portIdentifierFromPort = ({ transportType, code }: Port): PortIdentifier =>
  `${transportType}-${code}`

export const portOptionFromPort = (
  port: Pick<Port, '__typename' | 'transportType' | 'name' | 'code' | 'codeType'>
): PortOption => {
  const transportationCodeIcon = getTransportationCodeTypeIcon({ transportCodeType: port.codeType })
  return {
    label: (
      <>
        {transportationCodeIcon ? (
          <Icon icon={transportationCodeIcon} />
        ) : (
          <Icon icon={getTransportationTypeIcon({ transportType: port.transportType })} />
        )}
        <span>{` ${port.code} - ${port.name}`}</span>
      </>
    ),
    value: portIdentifierFromPort(port),
    name: port.name,
    code: port.code,
    codeType: port.codeType,
    transportType: port.transportType,
    __typename: 'PortOption',
  }
}

export const portFromPortOption = ({ name, code, codeType, transportType }: PortOption): Port => {
  const portType = portTypeFromTransportType({ transportType })
  return {
    name,
    code,
    codeType,
    [portType]: code,
    transportType,
    __typename: 'Port',
  }
}

export type PortIdentifier = `${TransportType}-${string}`

export const portIdentifierFromPortInput = ({ value }: { value: PortIdentifier }): PortInput => ({
  [portTypeFromTransportType({ transportType: value.split('-')[0] as TransportType })]:
    value.split('-')[1],
})

export const getSelectValue = (port: Port): string => {
  const portType = portTypeFromPort({ port })
  const portCode = port[portType]
  const transportationType = transportTypeFromPortType({ portType })
  return `${transportationType}-${portCode}`
}

export function portInputFromPort(port: Port): PortInput {
  return pick(port, ['airport', 'seaport', 'road', 'rail', 'dryport'])
}

export const getRelatedPartners = ({
  organization,
  shipment,
}: {
  organization?: UsersOrganizationFragment
  shipment: {
    __typename: 'Shipment'
    importer: Payload<Pick<Organization, 'id' | '__typename'>> | null
    exporter: Payload<Pick<Organization, 'id' | '__typename'>> | null
    forwarders: Payload<Pick<Organization, 'id' | '__typename'>>[]
    organizations: Payload<Pick<Organization, 'id' | '__typename'>>[]
  }
}) =>
  [shipment.importer, shipment.exporter, ...shipment.forwarders, ...shipment.organizations]
    .filter(isAvailable)
    // Filter duplicates
    .filter(
      (relatedOrg1, i, relatedOrgs) =>
        relatedOrgs.findIndex(
          (relatedOrg2) => relatedOrg1.id && relatedOrg2.id && relatedOrg2.id === relatedOrg1.id
        ) === i
    )
    // Filter user's organization
    .filter((relatedOrg) =>
      !organization ? true : !!relatedOrg.id && relatedOrg.id !== organization.id
    )
