// @flow strict
import matchSorter from 'match-sorter'

import type { Port } from '@graphql/server/flow'
import type { PortOption } from '@modules/shipment/types'

import { getTransportationTypePort } from 'modules/shipment/helpers'

export const itemToString = (i: ?PortOption | ?$Diff<Port, { __typename: string }>): string =>
  i ? `${i.code} - ${i.name}` : ''
export const itemToValue = (i: PortOption): Port => {
  const { transportType, code, name } = i || {}
  return {
    [(getTransportationTypePort({ transportType }): string)]: code,
    name,
    code,
    transportType,
    __typename: 'Port',
  }
}

export const filterItems = (q: string, options: PortOption[]): PortOption[] => {
  return matchSorter(options, q, {
    keys: ['code', 'name', itemToString],
  })
}
