import { ServerError, ServerParseError } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'
import { either, has, is, isEmpty, isNil, map, omit, pipe, reject, when } from 'ramda'

import {
  EntityTagInput,
  FileType,
  IntValue,
  Maybe,
  MetricValue,
  Port,
  PortInput,
  Scalars,
  StringValue,
  TimelineDateNestedInput,
  Value,
  Values,
} from '@graphql/server/typescript'

import {
  DeepPartial,
  Payload,
  UniversalBadRequest,
  UniversalForbidden,
  UniversalNotFound,
  nonNullable,
} from '@types'
import { defaultDistanceMetric } from '@utils/metric'

import { isDateObject } from './date'
import { getByPath, getByPathWithDefault, isEquals } from './fp'

export const replaceUndefined: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    map((x) => (x === undefined ? null : x)),
    map((a) => replaceUndefined(a))
  )
)

export const removeNulls: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    reject(isNil),
    map((a) => removeNulls(a))
  )
)

export const removeEmpty: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    reject(isEmpty),
    map((a) => removeEmpty(a))
  )
)

export const replaceEmptyString: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    map((x) => (x === '' ? null : x)),
    map((a) => replaceEmptyString(a))
  )
)

export const removeTypename: (params: any) => any = when(
  (value) => is(Array, value) || (is(Object, value) && !isDateObject(value)),
  pipe(
    (x) => (is(Object, x) && !is(Array, x) && has('__typename', x) ? omit(['__typename'], x) : x),
    map((a) => removeTypename(a))
  )
)

export const removeId: (params: any) => any = when(
  either(is(Array), is(Object)),
  pipe(
    (x) => (is(Object, x) && !is(Array, x) ? omit(['id'], x) : x),
    map((a) => removeId(a))
  )
)

export const cleanUpData: (params: any) => any = pipe(removeTypename, removeNulls)

export const cleanFalsyAndTypeName: (params: any) => any = pipe(
  removeTypename,
  removeNulls,
  removeEmpty
)

export const isServerError = (value: ErrorResponse['networkError']): value is ServerError =>
  !!(value && 'results' in value)

export const isServerParseError = (
  value: ErrorResponse['networkError']
): value is ServerParseError => !!(value && 'bodyText' in value)

export const isForbidden = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalForbidden => {
  return getByPath('__typename', data) === 'Forbidden'
}

export const isNotFound = (data: { [key: string]: any }): data is UniversalNotFound => {
  return getByPath('__typename', data) === 'NotFound'
}

export const isBadRequest = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalBadRequest => {
  return getByPath('__typename', data) === 'BadRequest'
}

export const isUnretrievable = (data: {
  __typename: string
  [key: string]: any
}): data is UniversalBadRequest | UniversalForbidden | UniversalNotFound => {
  return isBadRequest(data) || isForbidden(data) || isNotFound(data)
}

export const isRetrievable = <T extends { __typename: string; [key: string]: any }>(
  data: Payload<T>
): data is T => {
  return !isUnretrievable(data)
}

export const isUnavailable = (
  data?: { __typename: string; [key: string]: any } | null
): data is UniversalBadRequest | UniversalForbidden | UniversalNotFound | null | undefined => {
  return !data || isUnretrievable(data)
}

export const isAvailable = <T extends { __typename: string; [key: string]: any }>(
  data?: Payload<T> | null | undefined
): data is T => {
  return !isUnavailable(data)
}

export const getAvailable = <T extends { __typename: string; [key: string]: any }>(
  data?: Payload<T> | null | undefined
): T | null => {
  return isAvailable(data) ? data : null
}

export function assertIsIntValue(value: DeepPartial<Value>): asserts value is IntValue {
  if (value.__typename !== 'IntValue' || typeof value.int !== 'number') {
    throw new Error(`value is not an IntValue`)
  }
}

export function assertIsStringValue(value: DeepPartial<Value>): asserts value is StringValue {
  if (value.__typename !== 'StringValue' || typeof value.string !== 'string') {
    throw new Error(`value is not a StringValue`)
  }
}

export function assertIsValuesValue(value: DeepPartial<Value>): asserts value is Values {
  if (value.__typename !== 'Values' || !Array.isArray(value.values)) {
    throw new Error(`value is not a ValuesValue`)
  }
}

/** Does the change include an entity with an ID (modelled) */
export function isModelledEntityValue(
  value: DeepPartial<Value>
): value is { __typename: 'EntityValue'; entity: { __typename: any; id: string } } {
  return !!('entity' in value && value.entity && 'id' in value.entity)
}

/** Does the value include an entity with an ID (modelled) */
export function assertIsModelledEntityValue(
  value: DeepPartial<Value>
): asserts value is { __typename: 'EntityValue'; entity: { __typename: any; id: string } } {
  if (!isModelledEntityValue(value)) {
    throw new Error(`value is not a modelled EntityValue`)
  }
}

export const some = <T extends { __typename: string; [key: string]: any }>(
  data: Payload<T> | null | undefined
): T | null => {
  return !isUnavailable(data) ? data : null
}

export const arrayToHash = ({
  array = [],
  key = 'id',
  removeFalsy = true,
}: {
  array: any[]
  key?: string
  removeFalsy?: boolean
}) => {
  return array.reduce((arr, obj: any) => {
    if (removeFalsy) {
      if (obj[key]) {
        arr[obj[key]] = obj
      }
    } else {
      arr[obj[key]] = obj
    }

    return arr
  }, {})
}

export const extractForbiddenId = (data: {
  __typename: string
  [key: string]: any
}): { __typename: string; [key: string]: any } => {
  if (isForbidden(data)) {
    // @ts-ignore // TODO
    const id = getByPathWithDefault(null, 'reference.id', data)
    if (id) {
      return { ...data, id }
    }
  }
  return data
}

type FilesType = {
  id: Scalars['ID']
  name: string
  type: FileType
  memo?: Maybe<string>
  tags: Payload<{ id: Scalars['ID']; __typename: 'Tag' }>[]
  __typename: 'File'
}

export const findDeletedArrayData = <U extends { id: Scalars['ID']; __typename: string }>(
  originalValues: U[] | null,
  newValues: U[]
): U[] => {
  const origById =
    originalValues?.reduce<{
      [key: Scalars['ID']]: U
    }>((arr, value) => {
      arr[value.id] = value
      return arr
    }, {}) ?? {}

  const newById = newValues.reduce<{
    [key: Scalars['ID']]: U
  }>((arr, value) => {
    arr[value.id] = value
    return arr
  }, {})

  const deleted = Object.keys(origById).reduce<U[]>((arr, origId) => {
    if (!newById[origId]) {
      const originalObject = origById[origId]
      if (originalObject) {
        arr.push(originalObject)
      }
    }
    return arr
  }, [])

  return deleted
}

// For String and Number fields. Can be used for { [k: string]: any } in certain situations.
export const parseGenericField = <T, Key extends string>(
  key: Key,
  originalValue: T | null,
  newValue: T | null
): { [key in Key]: T } | Record<PropertyKey, never> => {
  if (!isEquals(originalValue, newValue)) {
    return { [key]: newValue } as { [key in Key]: T }
  }
  return {}
}

export const parseMemoField = <T extends string>(
  key: T,
  originalMemo: string | null,
  newMemo: string | null
): { [k: string]: any } => {
  const parsedOriginalMemo = originalMemo === '' ? null : originalMemo
  const parsedNewMemo = newMemo === '' ? null : newMemo

  if (!isEquals(parsedOriginalMemo, parsedNewMemo)) return { [key]: parsedNewMemo }
  return {}
}

// Use for Enum fields. Cannot have empty string as value.
export const parseEnumField = <T extends string>(
  key: T,
  originalEnum: string | null | undefined,
  newEnum: string | null | undefined
): { [key in T]: string | null } | Record<PropertyKey, never> => {
  const parsedOriginalEnum = originalEnum || null
  const parsedNewEnum = newEnum || null

  if (!isEquals(parsedOriginalEnum, parsedNewEnum)) {
    return { [key]: parsedNewEnum } as { [key in T]: string | null }
  }
  return {}
}

// Use for Date fields. Need to parse into Date { [k: string]: any }.
export const parseDateField = (
  key: string,
  originalDate: string | null | undefined,
  newDate: string | null | undefined
): { [k: string]: any } => {
  const parsedOriginalDate = originalDate ? new Date(originalDate) : null
  const parsedNewDate = newDate ? new Date(newDate) : null

  if (!isEquals(parsedOriginalDate, parsedNewDate)) return { [key]: parsedNewDate }
  return {}
}

// Use for Datetime fields.
export const parseDatetimeField = (
  key: string,
  originalDate: string | null | undefined,
  newDate: string | null | undefined
): { [k: string]: any } => {
  const parsedOriginalDate = originalDate ? new Date(originalDate).toISOString() : null
  const parsedNewDate = newDate ? new Date(newDate).toISOString() : null

  if (!isEquals(parsedOriginalDate, parsedNewDate)) return { [key]: parsedNewDate }
  return {}
}

// Use for Array of Ids.
export const parseArrayOfIdsField = <T extends string>(
  key: T,
  originalArray: ({ id: Scalars['ID'] } | object)[] | null,
  newArray: ({ id: Scalars['ID'] } | object)[] | null
): { [key in T]: Scalars['ID'][] } | Record<PropertyKey, never> => {
  const originalArrayOfIds = (originalArray || []).map((x) => ('id' in x ? x.id : null))
  const newArrayOfIds = (newArray || []).map((x) => ('id' in x ? x.id : null))

  if (!isEquals(originalArrayOfIds, newArrayOfIds))
    return { [key]: newArrayOfIds } as { [key in T]: Scalars['ID'][] }
  return {}
}

// Use for Tag Fields. This uses the new format
export const parseTagsField = <
  T extends string,
  U extends { id: Scalars['ID']; __typename: 'Tag' }
>(
  key: T,
  originalArray: Payload<U>[] | null,
  newArray: Payload<U>[] | null
): { [key in T]: EntityTagInput[] } | Record<PropertyKey, never> => {
  const parsedOriginalArray = originalArray?.filter(isAvailable)
  const parsedNewArray = newArray?.filter(isAvailable)

  const originalArrayOfIds = (originalArray || []).map((tag) => ('id' in tag ? tag.id : null))
  const newArrayOfIds = (newArray || []).map((tag) => ('id' in tag ? tag.id : null))

  if (!isEquals(originalArrayOfIds, newArrayOfIds)) {
    const deletedTags = findDeletedArrayData(parsedOriginalArray ?? [], parsedNewArray ?? []).map(
      (tag) => ({
        id: tag.id,
        deleted: true,
      })
    )

    const newTags = newArray
      ? newArray.filter(isAvailable).map((tag) => ('id' in tag ? { id: tag.id } : tag))
      : []

    return { [key]: [...newTags, ...deletedTags] } as { [key in T]: EntityTagInput[] }
  }
  return {} as Record<PropertyKey, never>
}

// Use for Single Id.
export const parseParentIdField = <T extends string>(
  key: T,
  originalParent: { id: Scalars['ID'] } | object | null | undefined,
  newParent: { id: Scalars['ID'] } | object | null | undefined
): { [key in T]: Scalars['ID'] | null } | Record<PropertyKey, never> => {
  if (isEquals(originalParent, newParent)) return {}

  if (!newParent) return { [key]: null } as { [key in T]: null }

  const newParentID = 'id' in newParent ? newParent.id : null

  if (!newParentID) return {}

  return { [key]: newParentID } as { [key in T]: Scalars['ID'] }
}

// Use to apply nested logic to child entities. Look at existing uses to understand more how to use it.
export const parseArrayOfChildrenField = <
  T extends string,
  U extends { id?: Scalars['ID']; __typename: string; [key: string]: any },
  R
>(
  key: T,
  originalChildren: Payload<U>[] | null,
  newChildren: Payload<U>[],
  parseInside: (oldChild: U | null, newChild: U) => R,
  forceSendIds = false
): { [key in T]: R[] } | Record<PropertyKey, never> => {
  if (!forceSendIds && isEquals(originalChildren, newChildren)) return {}

  const parsedNewChildren = newChildren.flatMap((newChild) => {
    if (isUnavailable(newChild)) return []
    const oldChild = originalChildren?.find(
      (originalChild): originalChild is U =>
        'id' in originalChild && 'id' in newChild && originalChild.id === newChild.id
    )

    return parseInside(oldChild ?? null, newChild)
  })

  return { [key]: parsedNewChildren } as { [key in T]: R[] }
}

type CustomFieldsType = {
  fieldValues: Payload<{
    value: StringValue | object
    fieldDefinition: Payload<{ id: Scalars['ID'] }>
    __typename: 'FieldValue'
  }>[]
}

// Use for Custom Fields. If there is at least one change in fieldValues, we need to send all fieldValues.
export const parseCustomFieldsField = (
  key: string,
  originalCustomFields: CustomFieldsType | null,
  newCustomFields: CustomFieldsType | null
): { [k: string]: any } => {
  if (isEquals(originalCustomFields, newCustomFields)) return {}

  const parsedOriginalFieldValues = getByPathWithDefault(
    [] as CustomFieldsType['fieldValues'],
    'fieldValues',
    originalCustomFields
  )
    .filter(isAvailable)
    .map((fieldValue) => {
      const value = 'string' in fieldValue.value ? fieldValue.value.string : null
      const fieldDefinitionId = getByPathWithDefault(null, 'fieldDefinition.id', fieldValue)

      return { value, fieldDefinitionId }
    })
  const parsedNewFieldValues = getByPathWithDefault(
    [] as CustomFieldsType['fieldValues'],
    'fieldValues',
    newCustomFields
  )
    .filter(isAvailable)
    .map((fieldValue) => {
      const value = 'string' in fieldValue.value ? fieldValue.value.string : null
      const fieldDefinitionId = getByPathWithDefault(null, 'fieldDefinition.id', fieldValue)

      return { value, fieldDefinitionId }
    })

  const parsedOriginalCustomFields = {
    fieldValues: parsedOriginalFieldValues,
  }
  const parsedNewCustomFields = {
    fieldValues: parsedNewFieldValues,
  }

  if (!isEquals(parsedOriginalCustomFields, parsedNewCustomFields))
    return {
      [key]: {
        ...parseGenericField('fieldValues', parsedOriginalFieldValues, parsedNewFieldValues),
      },
    }
  return {}
}

export const parseFilesField = <T extends string>({
  key,
  originalFiles,
  newFiles,
  isNewFormat,
}: {
  key: T
  originalFiles: Payload<FilesType>[]
  newFiles: Payload<FilesType>[]
  isNewFormat?: boolean
}) => {
  const originalFilesParsed = originalFiles.filter(isAvailable)
  const newFilesParsed = newFiles.filter(isAvailable)

  const changedFilesInput = parseArrayOfChildrenField(
    key,
    originalFilesParsed,
    newFilesParsed,
    (oldFile, newFile) => {
      return !oldFile ||
        Object.keys(oldFile).some(
          (oldFileKey) => !isEquals(oldFile[oldFileKey], newFile[oldFileKey])
        )
        ? {
            id: newFile.id,
            ...parseGenericField('name', getByPathWithDefault(null, 'name', oldFile), newFile.name),
            ...parseEnumField('type', getByPathWithDefault(null, 'type', oldFile), newFile.type),
            ...parseGenericField(
              'memo',
              getByPathWithDefault(null, 'memo', oldFile),
              newFile.memo ?? null
            ),
            ...parseTagsField('tags', getByPathWithDefault([], 'tags', oldFile), newFile.tags),
          }
        : undefined
    }
  )

  if (!isNewFormat || !(key in changedFilesInput)) {
    return changedFilesInput as Record<PropertyKey, never>
  }

  const parsedChangedFiles = changedFilesInput[key].filter(nonNullable)

  const allFilesById = {
    ...originalFilesParsed.reduce((arr, file) => {
      arr[file.id] = file
      return arr
    }, {}),
    ...newFilesParsed.reduce((arr, file) => {
      arr[file.id] = file
      return arr
    }, {}),
  }

  const deletedFiles = findDeletedArrayData(originalFilesParsed, newFilesParsed)

  const newFormattedFiles = [
    ...parsedChangedFiles.map((file) => {
      if ('type' in file) {
        file.type = allFilesById[file.id].type
      }
      return file
    }),
    ...deletedFiles.map((file) => ({
      id: file.id,
      type: allFilesById[file.id].type,
      orphan: true,
    })),
  ]

  return {
    [key]: newFormattedFiles,
  }
}

type ApprovalType = {
  approvedBy: {
    id: string
  } | null
  approvedAt: string | Date | null
}

// Use for Approval fields. Need to send only approvedBy, not approvedAt.
export const parseApprovalField = (
  key: string,
  originalApproval: null | ApprovalType,
  newApproval: null | ApprovalType
): { [k: string]: any } => {
  const originalApprovedById = getByPathWithDefault(null, 'approvedBy.id', originalApproval)
  const newApprovedById = getByPathWithDefault(null, 'approvedBy.id', newApproval)

  const originalApprovedAt =
    (originalApproval && originalApproval.approvedAt && new Date(originalApproval.approvedAt)) ||
    null
  const newApprovedAt =
    (newApproval && newApproval.approvedAt && new Date(newApproval.approvedAt)) || null

  const parsedOriginalApproval = {
    approvedById: originalApprovedById,
    approvedAt: originalApprovedAt,
  }
  const parsedNewApproval = {
    approvedById: newApprovedById,
    approvedAt: newApprovedAt,
  }

  if (!isEquals(parsedOriginalApproval, parsedNewApproval)) return { [key]: newApprovedById }
  return {}
}

// Use for Representative Batch. Send index, not id.
export const parseDefaultIndexField = (
  key: string,
  originalValues: null | {
    id: string
  },
  newValues: null | {
    id: string
  },
  sources: Array<{ [k: string]: any }>
): { [k: string]: any } => {
  const originalValuesId = getByPathWithDefault(null, 'id', originalValues)
  const newValuesId = getByPathWithDefault(null, 'id', newValues)

  if (isEquals(originalValuesId, newValuesId)) return {}

  let newValuesIndex = sources.findIndex((item) => item.id === newValuesId)
  // @ts-ignore // TODO
  if (newValuesIndex === -1) newValuesIndex = null

  return { [key]: newValuesIndex }
}

// For Size fields (length, width, height). Needs to handle case when null, need to inject default values for all.
export const parseSizeField = (
  key: string,
  originalSize: {
    height?: MetricValue
    width?: MetricValue
    length?: MetricValue
  } | void,
  newSize: { height?: MetricValue; width?: MetricValue; length?: MetricValue }
): any => {
  if (isEquals(originalSize, newSize)) return {}

  return {
    [key]: {
      height: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'height', newSize),
      width: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'width', newSize),
      length: getByPathWithDefault({ value: 0, metric: defaultDistanceMetric }, 'length', newSize),
    },
  }
}

/**
 * compares if two arrays are equal
 * @param isSameOrder Set to true if order position matters
 * note: upgrade this if object arrays are to be compared
 */
export const areArraysEqual = ({
  arr1,
  arr2,
  isSameOrder,
}: {
  arr1: any[]
  arr2: any[]
  key?: string
  isSameOrder?: boolean
}) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false
  }

  // .concat() to not mutate arguments
  // eslint-disable-next-line
  const _arr1 = arr1.concat()
  // eslint-disable-next-line
  const _arr2 = arr2.concat()

  if (!isSameOrder) {
    _arr1.sort()
    _arr2.sort()
  }

  for (let i = 0; i < _arr1.length; i++) {
    if (_arr1[i] !== _arr2[i]) {
      return false
    }
  }

  return true
}

type DateRevisionType = {
  id: string
  date: string
  type: string
  memo?: string | null
  __typename: 'TimelineDateRevision'
}
type TimelineDateType = {
  date?: string | null
  resultDate?: string | null
  approvedBy?: { id: string }
  approvedAt?: string | Date
  memo?: string | null
  timelineDateRevisions?: DateRevisionType[]
  __typename: 'TimelineDate'
}
export const parseTimelineDateField = <T extends string>(
  key: T,
  originalTimelineDate: TimelineDateType | object | null,
  newTimelineDate: TimelineDateType | object | null
): { [key in T]: TimelineDateNestedInput } | Record<PropertyKey, never> => {
  if (isEquals(originalTimelineDate, newTimelineDate)) return {}

  if (!newTimelineDate || !('date' in newTimelineDate)) {
    return {}
  }

  if (!originalTimelineDate || !('date' in originalTimelineDate)) {
    return {
      [key]: {
        date: newTimelineDate.date,
        resultDate: newTimelineDate.resultDate,
        approvedById: newTimelineDate.approvedBy?.id,
        memo: newTimelineDate.memo,
        timelineDateRevisions: newTimelineDate.timelineDateRevisions?.map(
          (revision: DateRevisionType) => ({
            date: revision.date,
            type: revision.type,
            memo: revision.memo,
          })
        ),
      },
    } as { [key in T]: TimelineDateNestedInput }
  }

  const parsedNewTimelineDate = {
    ...parseDatetimeField('date', originalTimelineDate.date ?? null, newTimelineDate.date ?? null),
    ...parseDatetimeField(
      'resultDate',
      originalTimelineDate.resultDate ?? null,
      newTimelineDate.resultDate ?? null
    ),
    ...parseApprovalField(
      'approvedById',
      {
        approvedBy: getByPathWithDefault(null, 'approvedBy', originalTimelineDate),
        approvedAt: getByPathWithDefault(null, 'approvedAt', originalTimelineDate),
      },
      {
        approvedBy: getByPathWithDefault(null, 'approvedBy', newTimelineDate),
        approvedAt: getByPathWithDefault(null, 'approvedAt', newTimelineDate),
      }
    ),
    ...parseArrayOfChildrenField(
      'timelineDateRevisions',
      getByPathWithDefault([], 'timelineDateRevisions', originalTimelineDate),
      getByPathWithDefault([], 'timelineDateRevisions', newTimelineDate),
      (oldDateRevision, newDateRevision) => ({
        ...(!oldDateRevision ? {} : { id: oldDateRevision.id }),
        ...parseDatetimeField('date', oldDateRevision?.date ?? null, newDateRevision.date),
        ...parseEnumField(
          'type',
          getByPathWithDefault(null, 'type', oldDateRevision),
          getByPathWithDefault(null, 'type', newDateRevision)
        ),
        ...parseMemoField(
          'memo',
          getByPathWithDefault(null, 'memo', oldDateRevision),
          getByPathWithDefault(null, 'memo', newDateRevision)
        ),
      })
    ),
  }

  return { [key]: parsedNewTimelineDate } as { [key in T]: TimelineDateNestedInput }
}

export const parsePortField = <T extends string>(
  key: T,
  originalPort: (Port | PortInput) | null,
  newPort: (Port | PortInput) | null
): { [key in T]: PortInput } | Record<PropertyKey, never> => {
  if (isEquals(originalPort, newPort)) return {}

  const parsedNewPort = {
    ...parseEnumField(
      'seaport',
      getByPathWithDefault(null, 'seaport', originalPort),
      getByPathWithDefault(null, 'seaport', newPort)
    ),
    ...parseEnumField(
      'airport',
      getByPathWithDefault(null, 'airport', originalPort),
      getByPathWithDefault(null, 'airport', newPort)
    ),
    ...parseEnumField(
      'road',
      getByPathWithDefault(null, 'road', originalPort),
      getByPathWithDefault(null, 'road', newPort)
    ),
    ...parseEnumField(
      'rail',
      getByPathWithDefault(null, 'rail', originalPort),
      getByPathWithDefault(null, 'rail', newPort)
    ),
    ...parseEnumField(
      'dryport',
      getByPathWithDefault(null, 'dryport', originalPort),
      getByPathWithDefault(null, 'dryport', newPort)
    ),
  }

  if (!Object.keys(parsedNewPort).length) {
    return {}
  }

  return { [key]: parsedNewPort } as { [key in T]: PortInput }
}
